@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Edu+SA+Beginner&family=Poppins&family=Raleway:ital,wght@0,400;1,200&display=swap" rel="stylesheet');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

:root {
  /* dark shades of primary color*/
  --clr-primary-6: hsl(21, 57%, 50%);
  --clr-primary-7: hsl(21, 65%, 59%);
  --clr-primary-8: hsl(21, 80%, 74%);
  --clr-primary-9: hsl(21, 94%, 87%);
  --clr-primary-10: hsl(21, 100%, 94%);

  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /*grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
.card {
  /*HERE*/
  min-width: 14rem;
  min-height: 2rem;
  padding: 0.5rem;
  margin: 0 auto;
  background-color: #f3f3f3;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s; /*new*/
  min-width: 14rem;
  max-width: 18rem; /* Increase max-width to accommodate longer text */
  padding: 1rem;
  margin: 0 auto;
  background-color: #f3f3f3;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px; /* Rounded corners */
  text-align: center; /* Center the text */
  word-wrap: break-word; /* Break words to the next line if they're too long */
  min-width: 14rem;
  max-width: 18rem; /* Increase max-width to accommodate longer text */
  padding: 1rem;
  margin: 0 auto;
  background-color: #f3f3f3;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px; /* Rounded corners */
  text-align: center; /* Center the text */
  word-wrap: break-word; /* Break words to the next line if they're too long */
  border: 1px solid #ccc; /* Add a border */
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
  border-color: #008000; /* Change border color on hover */
}

.prices {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  /* max-width: (var(--max-width)); */
  padding: 0 20rem 0;
  /* background-color: #f3f3f3; */
  /* background-color: grey; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  /* padding: 0 20rem 0; */
  /* background-color: #f3f3f3; Change the background color of the prices section */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Add a shadow to the prices section */
}

.prices .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  height: 5rem;
  width: 3rem;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  width: 4rem;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  width: 4rem;
  margin: 0.5rem;
}

.prices .card:hover {
  transform: scale(1.05);
}

.navbar {
  background-color: rgb(28, 24, 24);
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
}

.navbar a {
  color: white;
  text-decoration: none;
  padding: 10px;
  font-size: larger;
}

.navbar a:hover {
  color: black;
  /* background-color: white; */
  background-color: #f3f3f3;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.hero h1,
.hero h4,
.hero h2 {
  text-align: center;
  color: #f3f3f3;
  font-size: 2rem; /* Adjust as needed */
}
/* .hero h4 {
  max-width: 50rem;
  color: white;
} */
.hero h4 {
  max-width: 50rem;
  color: white;
  font-size: 1.5em; /* Increase the font size */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add a text shadow */
  font-weight: bold; /* Make the text bold */
  text-align: center; /* Center the text */
  padding: 10px; /* Add some padding */
  border-radius: 5px; /* Rounded corners for the background */
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
}

.hero h1 {
  font-size: 3.5rem;
}
.hero h2 {
  font-size: 3rem;
  margin-bottom: 3rem;
}

.text-h1 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
}
.text-p {
  font-size: 2rem;
  text-align: center;
  font-weight: 1rem;
  margin-bottom: 2rem;
}
.section-center {
  margin: 0 auto;
  margin-top: 4rem;
  width: 80vw;

  /* have to have a height */
  height: 450px;
  max-width: 800px;
  text-align: center;
  position: relative;
  display: flex;
  /* overflow: hidden; */
}
.prev,
.next {
  position: absolute;
  top: 200px;
  transform: translateY(-50%);
  background: var(--clr-grey-5);
  color: var(--clr-white);
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1rem;
  border-radius: var(--radius);
  cursor: pointer;
  transition: var(--transition);
}
.prev:hover,
.next:hover {
  background-color: rgb(6, 165, 6);
}
.prev {
  left: 0;
}
.next {
  right: 0;
}
@media (max-width: 800px) {
  .prev,
  .next {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
  }
  .prices {
    padding: 5rem;
  }
  .social {
    font-size: 1.4rem;
    font-weight: bold;
  }
  .social div a {
    font-size: 0.8rem; /* Adjust as needed */
  }
  .contacts {
    flex-direction: column;
  }

  .map {
    max-width: 20rem;
    min-height: 30rem;
    order: 2;
  }

  .social {
    order: 1;
  }
}
.img-view {
  width: 20rem;
  height: 20rem;
}

article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
article.lastSlide {
  transform: translateX(-100%);
}
article.nextSlide {
  transform: translateX(100%);
}
.article-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: var(--transition);
}
.contacts {
  display: flex;
  justify-content: space-between;
}

.map,
.social {
  flex: 1;
  margin: 10px;
  margin-bottom: 7.7rem;
}

.social {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem; /* Adjust as needed */
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.social div {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

.social div p,
.social div a {
  margin-top: 1rem;
  margin-left: 0.5rem;
}
/* CHANGES*/
.section-center {
  margin: 0 auto;
  margin-top: 4rem;
  width: 80vw;
  height: 450px;
  max-width: 800px;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden; /* Hide the slides that are out of view */
  /* margin-bottom: 10px; */
}

.article-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: var(--transition);
}

.img-view {
  width: 100%; /* Take up the full width of the parent */
  height: 100%; /* Take up the full height of the parent */
  object-fit: contain;
}

article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}

article.lastSlide {
  transform: translateX(-100%);
}

article.nextSlide {
  transform: translateX(100%);
}

.img-view.zoomed {
  transform: scale(1.5);
  transition: transform 0.4s;
}

.footer {
  margin-top: auto;
  background-color: rgb(28, 24, 24);
  color: white;
  text-align: center;
  padding: 10px 0;
  font-size: 1.5em;
}
/* CHANGES*/
.contacts {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  /* background-color: var(--clr-grey-10); */
}
.container {
  background-image: url("./image/WhatsApp\ Image\ 2023-12-03\ at\ 21.18.40_2e6d0d3a.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.price-section {
  background-image: url("./image/WhatsApp\ Image\ 2023-12-03\ at\ 21.19.27_fd58f60b.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.map {
  flex: 1;
  margin-right: 2rem;
  border-radius: var(--radius);
  overflow: hidden;
}

/* .social {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
} */

.social h2 {
  margin-bottom: 1rem;
  color: #f3f3f3;
}

.social div {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.social div svg {
  margin-right: 1rem;
  color: rgb(12, 12, 107);
}

.social div a {
  color: #f3f3f3;
  font-weight: bolder;
  text-decoration: none;
}

.social div a:hover {
  color: red;
}
.map {
  flex: 1;
  margin-right: 2rem;
  border-radius: var(--radius);
  overflow: hidden;
  box-shadow: var(--light-shadow);
  transition: var(--transition);
}

.map:hover {
  box-shadow: var(--dark-shadow);
  transform: scale(1.02);
}
.contacts,
.map {
  opacity: 0;
  transition: opacity 3s ease-in-out;
}

.contacts.visible,
.map.visible {
  opacity: 1;
}

/* .card {
  min-width: 14rem;
  max-width: 18rem; 
  padding: 1rem;
  margin: 0 auto;
  background-color: #f3f3f3;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px; 
  text-align: center; 
  word-wrap: break-word;
} */
/* 
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
} */

/* .prices {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 0 20rem 0;
  background-color: white;
} */

/* .prices .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  width: 4rem;
  margin: 0.5rem;
} */

/* .prices .card:hover {
  transform: scale(1.05);
} */

/* New styles */
.card p:first-child {
  font-size: 1rem; /* Make the first paragraph (the title) larger */
  font-weight: bold; /* Make the first paragraph bold */
  margin-bottom: 0.5em; /* Add some space between the title and the price */
}

.card p:last-child {
  color: #008000; /* Change the color of the price to green */
}
/* Change*/
/* .card {
  min-width: 14rem;
  max-width: 18rem; 
  padding: 1rem;
  margin: 0 auto;
  background-color: #f3f3f3;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
  text-align: center; 
  word-wrap: break-word; 
  border: 1px solid #ccc; 
} */

/* .card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
  border-color: #008000; 
} */

/* .prices .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  width: 4rem;
  margin: 0.5rem;
} */

/* .prices .card:hover {
  transform: scale(1.05);
} */

/* New styles */
.card p:first-child {
  font-size: 1rem; /* Make the first paragraph (the title) larger */
  font-weight: bold; /* Make the first paragraph bold */
  margin-bottom: 0.5em; /* Add some space between the title and the price */
}

.card p:last-child {
  color: #008000; /* Change the color of the price to green */
}
/* .prices {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;

  background-color: #f3f3f3; 
  border-radius: 10px; 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
} */

/* Changes*/
/* @media (max-width: 600px) {
  .contacts {
    flex-direction: column;
  }

  .map {
    order: 2;
  }

  .social {
    order: 1;
  }
} */
